import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearch = useCallback(
    debounce((query) => {
      onSearch(query.trim());
    }, 300),
    [onSearch]
  );

  const handleChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    debouncedSearch(query);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchTerm.trim());
  };

  return (
    <div className="search-bar">
      <form onSubmit={handleSubmit}>
        <input 
          type="text"
          value={searchTerm}
          onChange={handleChange}
          placeholder="Search for products..."
        />
      </form>
    </div>
  );
};

export default SearchBar;
