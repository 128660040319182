import React from 'react';

const ClearFilters = ({ onClear }) => {
  return (
    <button className="clear-filters" onClick={onClear}>
      Clear All Filters
    </button>
  );
};

export default ClearFilters;
