import React from 'react';
import PriceFilter from './PriceFilter';
import RatingFilter from './RatingFilter';
import SortProducts from './SortProducts';
import ClearFilters from './ClearFilters';

const Filters = ({ onPriceFilter, onRatingFilter, onSort, onClear }) => (
  <div className="filters-section">
    <PriceFilter onFilter={onPriceFilter} />
    <RatingFilter onFilter={onRatingFilter} />
    <SortProducts onSort={onSort} />
    <ClearFilters onClear={onClear} />
  </div>
);

export default Filters;
