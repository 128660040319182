
import React from 'react';

const PriceFilter = ({ onFilter }) => {
  const handleChange = (e) => {
    onFilter(e.target.value);
  };

  return (
    <div className="price-filter">
      <select onChange={handleChange}>
        <option value="">All Prices</option>
        <option value="under10">Under £10</option>
        <option value="10to50">£10 - £50</option>
        <option value="50plus">Over £50</option>
      </select>
    </div>
  );
};

export default PriceFilter;
