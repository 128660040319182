import React from 'react';

const NoResults = ({ searchQuery }) => {
  return (
    <div className="no-results">
      <div className="no-results-icon">🔍</div>
      <h2>No results found</h2>
      <p>We couldn't find any products matching "{searchQuery}"</p>
      <p>Try adjusting your search or filters</p>
    </div>
  );
};

export default NoResults;
