// getPriceLabel.js
//import React from 'react';
const getPriceLabel = (range) => {
    switch (range) {
      case 'under10': return 'Under £10';
      case '10to50': return '£10 - £50';
      case '50plus': return 'Over £50';
      default: return '';
    }
  };
  
  export default getPriceLabel;
  