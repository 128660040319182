import React from 'react';

function RatingFilter({ onFilter }) {
  return (
    <div className="rating-filter">
      <select onChange={(e) => onFilter(e.target.value)}>
        <option value="">Rating: All</option>
        <option value="4">4+ Stars</option>
        <option value="3">3+ Stars</option>
        <option value="2">2+ Stars</option>
      </select>
    </div>
  );
}

export default RatingFilter;
