import React from 'react';

const ProductSkeleton = () => {
  return (
    <div className="product-skeleton">
      <div className="skeleton-img"></div>
      <div className="skeleton-text"></div>
      <div className="skeleton-price"></div>
      <div className="skeleton-rating"></div>
    </div>
  );
};

export default ProductSkeleton;
