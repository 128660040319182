import React, { useState, useEffect, useCallback } from 'react';
import { filter, includes, toLower } from 'lodash';
import SearchBar from './components/SearchBar';
import Filters from './components/Filters';
import ProductSkeleton from './components/ProductSkeleton';
import NoResults from './components/NoResults';
import BackToTop from './components/BackToTop';
import useInfiniteScroll from './hooks/useInfiniteScroll';
import getPriceLabel from './components/getPriceLabel';
import getSortLabel from './components/getPriceLabel';

import './styles/App.css';

function App() {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [priceRange, setPriceRange] = useState('');
    const [ratingFilter, setRatingFilter] = useState('');
    const [sortType, setSortType] = useState('');
    const [activeFilters, setActiveFilters] = useState({
        price: '',
        rating: '',
        sort: ''
    });
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const applyFilters = useCallback((products, price, rating) => {
        let filtered = [...products];
        
        if (price) {
            filtered = filter(filtered, product => {
                const productPrice = parseFloat(product.price?.replace('£', ''));
                switch(price) {
                    case 'under10': return productPrice < 10;
                    case '10to50': return productPrice >= 10 && productPrice <= 50;
                    case '50plus': return productPrice > 50;
                    default: return true;
                }
            });
        }
        
        if (rating) {
            filtered = filter(filtered, product => {
                const productRating = parseFloat(product.rating?.split(' ')[0]) || 0;
                return productRating >= parseFloat(rating);
            });
        }
        
        setFilteredProducts(filtered);
    }, []);

    const handlePriceFilter = useCallback((range) => {
        setPriceRange(range);
        setActiveFilters(prev => ({...prev, price: getPriceLabel(range)}));
        applyFilters(products, range, ratingFilter);
    }, [products, ratingFilter, applyFilters]);

    const handleRatingFilter = useCallback((rating) => {
        setRatingFilter(rating);
        setActiveFilters(prev => ({...prev, rating}));
        
        let filtered = [...products];
        if (rating) {
            filtered = filter(filtered, product => {
                if (!product.rating) return false;
                const productRating = parseFloat(product.rating.split(' ')[0]);
                return productRating >= parseFloat(rating);
            });
        }
        
        setFilteredProducts(filtered);
    }, [products]);

    const handleSort = (type) => {
        setSortType(type);
        setActiveFilters({...activeFilters, sort: getSortLabel(type)});
        
        const sorted = [...filteredProducts].sort((a, b) => {
            switch(type) {
                case 'price-low':
                    const priceA = parseFloat(a.price?.replace('£', '')) || 0;
                    const priceB = parseFloat(b.price?.replace('£', '')) || 0;
                    return priceA - priceB;
                case 'price-high':
                    const priceHighA = parseFloat(a.price?.replace('£', '')) || 0;
                    const priceHighB = parseFloat(b.price?.replace('£', '')) || 0;
                    return priceHighB - priceHighA;
                case 'rating':
                    const ratingA = parseFloat(a.rating?.split(' ')[0]) || 0;
                    const ratingB = parseFloat(b.rating?.split(' ')[0]) || 0;
                    return ratingB - ratingA;
                default:
                    return 0;
            }
        });
        
        setFilteredProducts(sorted);
    };

    const handleClearFilters = () => {
        setPriceRange('');
        setRatingFilter('');
        setSortType('');
        setActiveFilters({
            price: '',
            rating: '',
            sort: ''
        });
        
        const priceSelect = document.querySelector('.price-filter select');
        const ratingSelect = document.querySelector('.rating-filter select');
        const sortSelect = document.querySelector('.sort-products select');
        
        if (priceSelect) priceSelect.value = '';
        if (ratingSelect) ratingSelect.value = '';
        if (sortSelect) sortSelect.value = '';

        setFilteredProducts([...products]);
    };

    const fetchProducts = async (query = '') => {
        console.log('Fetching products with query:', query);
        setLoading(true);
        setError(null);
        setSearchQuery(query);
    
        try {
            const response = await fetch('https://mavripetra.com/api/products');
            if (!response.ok) throw new Error('Failed to fetch products');
            const data = await response.json();
        
            const filtered = query
                ? data.filter(product => product.title.toLowerCase().includes(query.toLowerCase()))
                : data;
            
            setProducts(data);
            setFilteredProducts(filtered);
        } catch (error) {
            setError('Failed to load products');
            console.error('Error:', error);
        }
        setLoading(false);
    };

    const loadMoreProducts = useCallback(async (done) => {
        if (!hasMore) {
            done();
            return;
        }
        try {
            const nextPage = page + 1;
            const response = await fetch(`https://mavripetra.com/api/products?page=${nextPage}`);
            const newProducts = await response.json();
            
            if (newProducts.length === 0) {
                setHasMore(false);
            } else {
                setProducts(prev => [...prev, ...newProducts]);
                setPage(nextPage);
            }
        } catch (error) {
            console.error('Error loading more products:', error);
        }
        done();
    }, [page, hasMore]);

    const [isFetching, setIsFetching] = useInfiniteScroll(loadMoreProducts);

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <div className="App">
            <header>
                <h1>Amazon Deals Finder</h1>
                <SearchBar onSearch={fetchProducts} />
                <div className="filters-container">
                    <Filters 
                        onPriceFilter={handlePriceFilter} 
                        onRatingFilter={handleRatingFilter} 
                        onSort={handleSort} 
                        onClear={handleClearFilters} 
                    />
                </div>
            </header>
            
            <div className="products-section">
                {loading ? (
                    <div className="products-grid">
                        {Array(8).fill().map((_, index) => (
                            <ProductSkeleton key={index} />
                        ))}
                    </div>
                ) : filteredProducts.length === 0 ? (
                    <NoResults searchQuery={searchQuery} />
                ) : (
                    <div className="products-grid">
                        {filteredProducts.map((product) => (
                            <div 
                                key={product.asin} 
                                className="product-card"
                                onClick={() => window.open(`https://www.amazon.co.uk/dp/${product.asin}`, '_blank')}
                            >
                                <img 
                                    src={product.image_url} 
                                    alt={product.title} 
                                    loading="lazy"
                                    width="300"
                                    height="300"
                                    srcSet={`${product.image_url} 300w, ${product.image_url} 600w`}
                                    sizes="(max-width: 768px) 100vw, 300px"
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'contain',
                                        maxHeight: '300px'
                                    }}
                                />
                                <h3>{product.title}</h3>
                                <p className="price">{product.price}</p>
                                {product.rating && <p className="rating">★ {product.rating}</p>}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <BackToTop />
        </div>
    );
}

export default App;
