import React from 'react';

function SortProducts({ onSort }) {
  return (
    <div className="sort-products">
      <select onChange={(e) => onSort(e.target.value)}>
        <option value="">Sort By</option>
        <option value="price-low">Price: Low to High</option>
        <option value="price-high">Price: High to Low</option>
        <option value="rating">Highest Rated</option>
      </select>
    </div>
  );
}

export default SortProducts;
